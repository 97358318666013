export const ROUTE_PATH = {
  chat: { path: '/', title: 'CHAT' },
  information: { path: '/information/', title: 'INFO' },
  prompts: { path: '/prompts/', title: '共有プロンプト' },
  myPrompts: { path: '/prompts/myPrompt/', title: 'マイプロンプト' },
  favoritePrompts: { path: '/prompts/favorite', title: 'お気に入りプロンプト' },
  createPrompts: { path: '/prompts/create/', title: '新規プロンプト追加' },
  promptDetail: { path: '/prompts/', title: 'プロンプト詳細' },
  boxes: { path: '/prompts/boxes/', title: '共有プロンプト' },
  teams: { path: '/teams/', title: 'TEAM' },
  myTeams: { path: '/teams/my-list/', title: 'TEAM' },
  createTeam: { path: '/teams/create/', title: 'TEAM' },
  admin: {
    root: { path: '/admin/', title: '' },
    students: { path: '/admin/users/students', title: 'ユーザー一覧' },
    teachers: { path: '/admin/users/teachers', title: 'ユーザー一覧' },
    chatRooms: { path: '/admin/chat-rooms/', title: 'チャット履歴一覧' },
    chatRoom: { path: '/admin/chat-rooms/', title: 'CHAT' },
    prompts: { path: '/admin/prompts/', title: 'プロンプト一覧' },
    feedback: { path: '/admin/feedback/', title: 'フィードバック一覧' },
    group: { path: '/admin/group/', title: 'グループ一覧' },
    policy: { path: '/admin/policy/', title: 'ポリシー違反一覧' },
  },
}
