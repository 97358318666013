import { useState } from 'react'
import { useParams, usePathname, useRouter } from 'next/navigation'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import { SideMenuItemWithDelete } from '@/components/layout/SideMenu/SideMenuItemWithDelete'
import { BasicAccordion } from '@/components/uis/Accordion/BasicAccordion'
import { PlusCircleIcon } from '@/components/icon/PlusCircleIcon'
import { BasicButton } from '@/components/uis/Button/BasicButton'
import { useAuthContext } from '@/context/AuthContext'
import { useDisclosure } from '@/utils/hooks/useDisclosure'
import { SideMenuItem } from '@/components/layout/SideMenu/SideMenuItem'
import { StarIcon } from '@/components/icon/StarIcon'

import { useDeleteMember, useGetMembers } from '../../hooks/useMember'
import { Member } from '../../types'
import { DeleteModal } from './DeleteModal'

const Center = styled(Box)`
  text-align: center;
`
type Props = {
  expandedType: string
  expanded: string | false
  handleChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void
}

export const Members = ({ expandedType, expanded, handleChange }: Props) => {
  const router = useRouter()
  const pathName = usePathname()

  const { chatRoomId } = useParams()
  const id = !chatRoomId
    ? ''
    : typeof chatRoomId === 'string'
      ? chatRoomId
      : chatRoomId[0]

  const { isOpen, handleOpen, handleClose } = useDisclosure()
  const [selectedUser, setSelectedUser] = useState<Member>()

  const { user } = useAuthContext()
  const token = user?.token ?? ''
  const userId = user?.id ?? ''

  const { data } = useGetMembers({ token, id })

  const { mutate } = useDeleteMember()

  const handleModalOpen = (userId: string) => {
    const user = data?.members.find((f) => f.user_id === userId)
    if (!user) return
    setSelectedUser(user)
    handleOpen()
  }

  const handleModalSubmit = () => {
    mutate({ token, teamId: id, userId: selectedUser?.user_id ?? '' })
    handleClose()
    setSelectedUser(undefined)
  }

  const handleAddMemberClick = () => {
    router.push(pathName + 'add')
  }

  return (
    <>
      <BasicAccordion
        title={`メンバー（${data?.members.length ? data?.members.length + 1 : 1} 人）`}
        expandedType={expandedType}
        expanded={expanded}
        handleChange={handleChange}
      >
        <Center>
          <BasicButton
            label='メンバー追加'
            icon={<PlusCircleIcon />}
            width='272px'
            height='40px'
            variant='outlined'
            onClick={handleAddMemberClick}
          />
        </Center>
        {data ? (
          <>
            <SideMenuItem
              key={data.owner_id}
              text={data.owner_name}
              href=''
              isCurrent={false}
              isText
              endIcon={<StarIcon />}
            />
            {data.members.map((member) =>
              data.owner_id !== userId ? (
                <SideMenuItem
                  key={member.user_id}
                  text={member.user_name}
                  href=''
                  isCurrent={false}
                  isText
                />
              ) : (
                <SideMenuItemWithDelete
                  key={member.user_id}
                  id={member.user_id}
                  text={member.user_name}
                  modalOpen={handleModalOpen}
                  isCurrent={false}
                />
              ),
            )}{' '}
          </>
        ) : (
          '情報取得に失敗しました'
        )}
      </BasicAccordion>
      <DeleteModal
        open={isOpen}
        title='メンバーを削除します'
        onClose={handleClose}
        submit={handleModalSubmit}
      >
        <>
          以下のメンバーをルームから削除します。よろしいですか？
          <br />
          {selectedUser?.user_name}
        </>
      </DeleteModal>
    </>
  )
}
