import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { MutationKeys, QueryKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { useAuthContext } from '@/context/AuthContext'
import { Members } from '../types'

export const useGetMembers = ({ token, id }: { token: string; id: string }) => {
  const { apiClient } = useApiClient()
  const { controller } = useAuthContext()

  return useQuery({
    queryKey: QueryKeys.members,
    queryFn: () =>
      apiClient(token).get<Members>(API.members(id), { signal: controller?.signal }),
    select: (res) => res.data,
    enabled: !!token && !!id,
  })
}

export const useAddMember = () => {
  const { apiClient } = useApiClient()
  // TODO:useApiClientでcontent-typeを決められるようにしたい
  const header = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return useMutation({
    mutationKey: MutationKeys.addMember,
    mutationFn: async ({
      token,
      id,
      data,
    }: {
      token: string
      id: string
      data: FormData
    }) => await apiClient(token).patch(API.addMember(id), data, header),
  })
}

export const useDeleteMember = () => {
  const { apiClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: MutationKeys.deleteMember,
    mutationFn: async ({
      token,
      teamId,
      userId,
    }: {
      token: string
      teamId: string
      userId: string
    }) => await apiClient(token).delete(API.deleteMember(teamId, userId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QueryKeys.members })
    },
  })
}
