import { usePathname } from 'next/navigation'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import { SideMenuItem } from '@/components/layout/SideMenu/SideMenuItem'
import { TEAM_MENU_ITEMS } from '@/utils/constants/teamMenuItems'
import { textColor } from '@/utils/themeConfigs/customTheme'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0;
`
export const TeamSideMenu = () => {
  const pathName = usePathname()

  return (
    <Container>
      {TEAM_MENU_ITEMS.map((item) => {
        const isCurrent = pathName === item.href

        return (
          <SideMenuItem
            key={item.id}
            text={item.text}
            href={item.href}
            startIcon={item.icon(isCurrent)}
            isCurrent={isCurrent}
            currentTextColor={textColor.red}
          />
        )
      })}
    </Container>
  )
}
