import { Box, styled, Typography } from '@mui/material'
import Link from 'next/link'
import { bgColor, fontWeight, textColor } from '@/utils/themeConfigs/customTheme'

const StyledLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'isCurrent' })<{
  isCurrent: boolean
}>`
  width: 100%;
  height: 50px;
  padding: 15px 16px 15px 24px;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: ${({ isCurrent }) =>
    isCurrent ? bgColor.halfTransparentWhite : 'inherit'};
`

const StyledItem = styled(Box)`
  width: 100%;
  height: 50px;
  padding: 15px 16px 15px 24px;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: 'inherit';
`

const Item = styled(Box, { shouldForwardProp: (prop) => prop !== 'isCurrent' })<{
  isCurrent: boolean
}>`
  width: 100%;
  height: 50px;
  padding: 15px 16px 15px 24px;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: ${({ isCurrent }) =>
    isCurrent ? bgColor.halfTransparentWhite : 'inherit'};
`

const ItemText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCurrent' && prop !== 'currentTextColor',
})<{ isCurrent: boolean; currentTextColor?: string }>`
  font-weight: ${({ isCurrent }) => (isCurrent ? fontWeight.bold : fontWeight.normal)};
  font-size: 0.875rem;
  line-height: 1.448;
  color: ${({ currentTextColor, isCurrent }) =>
    isCurrent ? (currentTextColor ?? textColor.dark) : textColor.dark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

type Props = {
  text: string
  href: string
  startIcon?: React.ReactElement
  endIcon?: React.ReactElement
  isCurrent: boolean
  isText?: boolean
  currentTextColor?: string
}

export const SideMenuItem = ({
  text,
  href,
  startIcon,
  endIcon,
  isCurrent,
  isText,
  currentTextColor,
}: Props) => {
  // 管理画面のプロンプト画面のみ詳細ページがあり、その際にプロンプト一覧ボタンを押下時に一覧ページに飛べるようにするため、
  // 「/admin/prompts」が含まれる場合はisCurrentでもリンクにする
  if (isCurrent && !isText && !href.includes('/admin/prompts')) {
    return (
      <Item isCurrent={isCurrent}>
        {startIcon}
        <ItemText isCurrent={isCurrent} currentTextColor={currentTextColor}>
          {text}
        </ItemText>
        {endIcon}
      </Item>
    )
  }

  if (isText) {
    return (
      <StyledItem>
        {startIcon}
        <ItemText isCurrent={isCurrent} currentTextColor={currentTextColor}>
          {text}
        </ItemText>
        {endIcon}
      </StyledItem>
    )
  }

  return (
    <StyledLink href={href} isCurrent={isCurrent}>
      {startIcon}
      <ItemText isCurrent={isCurrent} currentTextColor={currentTextColor}>
        {text}
      </ItemText>
      {endIcon}
    </StyledLink>
  )
}
