import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { usePathname } from 'next/navigation'

import { SideMenuItem } from '@/components/layout/SideMenu/SideMenuItem'
import { PROMPT_MENU_ITEMS } from '@/utils/constants/promptMenuItems'
import { textColor } from '@/utils/themeConfigs/customTheme'

import { Boxes } from './Box/Boxes'
import { PromptSideMenuProps } from '../../types'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const PromptMenu = styled(Box)`
  border-bottom: 1px solid ${textColor.lightGray};
  box-shadow: 0px 4px 12px 0px #0000000d;
  padding: 40px 0 24px;
`

export const PromptSideMenu = ({
  token,
  userId,
  isStudent = true,
  isAdmin = false,
}: PromptSideMenuProps) => {
  const pathName = usePathname()

  return (
    <Container>
      <PromptMenu>
        {PROMPT_MENU_ITEMS.map((item) => {
          const isCurrent =
            (pathName.endsWith('/') ? pathName.slice(0, -1) : pathName) === item.href
          return (
            <SideMenuItem
              key={item.id}
              text={item.text}
              href={item.href}
              startIcon={item.icon(isCurrent)}
              isCurrent={isCurrent}
              currentTextColor={textColor.red}
            />
          )
        })}
      </PromptMenu>
      <Boxes token={token} userId={userId} isStudent={isStudent} isAdmin={isAdmin} />
    </Container>
  )
}
