export const DeleteIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width='11'
      height='10'
      viewBox='0 0 11 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.7644 0.764404L1.29321 9.23559M1.29321 0.764404L9.7644 9.23559'
        stroke={color ? color : '#777777'}
        strokeWidth='1.41657'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
