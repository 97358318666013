import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { useAuthContext } from '@/context/AuthContext'
import { Index } from '@/components/layout/SideMenu/Index/IndexAccordion'

export const useFetchIndex = ({
  token,
  searchWord,
  isComposition,
}: {
  token: string
  searchWord: string
  isComposition: boolean
}) => {
  const { apiClient } = useApiClient()
  const { controller } = useAuthContext()

  return useQuery({
    queryKey: [...QueryKeys.index, searchWord],
    queryFn: () =>
      apiClient(token)
        .get<Index>(API.index(searchWord), { signal: controller?.signal })
        .then((res) => res.data),
    enabled: !!token && !isComposition,
  })
}
