import { useRef } from 'react'
import { useRouter } from 'next/router'
import axios, { AxiosError } from 'axios'

import { useSnackbarContext } from '@/context/SnackbarContext'
import { useAuthContext, useSetAuthContext } from '@/context/AuthContext'
import { auth } from '../libs/firebase/client'
import { baseURL } from '../apis/client'
import { cookies } from '../helpers/cookie'

export const useApiClient = () => {
  const { showSnackbar } = useSnackbarContext()
  const { controller } = useAuthContext()
  const { setUser } = useSetAuthContext()

  const { deleteCookie } = cookies()
  const changeRoute = useRef(false)
  const router = useRouter()

  const apiClient = (token: string) => {
    const client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    client.interceptors.request.use((request) => {
      const expStr = localStorage.getItem('AUTH-EXP')
      const exp = Number(expStr)

      if (request.url?.includes('login') || (exp && Date.now() < exp * 1000)) {
        if (changeRoute.current) changeRoute.current = false

        return request
      } else {
        controller?.abort()
        return request
      }
    })

    client.interceptors.response.use(
      (response) => {
        if (
          response.status === 200 &&
          typeof response.data === 'string' &&
          response.data !== '' &&
          !(response.request as XMLHttpRequest).responseURL?.includes('csv')
        ) {
          if (showSnackbar)
            showSnackbar({
              text: response.data,
              severity: 'success',
            })
        }
        // MEMO: 検索結果0件でsnackbarを出す場合はここ
        // status204 && response.data === ""で分岐して出す
        return response
      },
      async (error: AxiosError) => {
        if (error.response?.status === 401 || axios.isCancel(error)) {
          // useAuthを呼ぶとMaximum call stack size exceeded.になるのでベタでlogout処理を記載
          await auth.signOut()
          setUser(null)
          localStorage.setItem('AUTH-EXP', '0')
          localStorage.setItem('INFO-UNREAD', '0')
          deleteCookie('CloudFront-Policy')
          deleteCookie('CloudFront-Signature')
          deleteCookie('CloudFront-Key-Pair-Id')

          // changeRoute：Abort fetching for route対策
          // APIが複数同時に動いた際に発生
          if (!changeRoute.current) {
            changeRoute.current = true
            await router.push('/login', undefined, { shallow: true })
          }
        }

        // ブラウザを開いたままトークン期限が切れた場合
        // 遷移を伴わない場合、logoutメソッドは動かないのでここにも記載が必要
        if (error.isAxiosError && axios.isCancel(error)) {
          if (showSnackbar) {
            showSnackbar({
              text: '一定時間操作がなかったため、ログアウトしました',
              severity: 'success',
            })
          }
        } else if (
          error.isAxiosError &&
          typeof error.response?.data === 'object' &&
          (error.response.data as { detail: string }).detail
        ) {
          if (showSnackbar)
            showSnackbar({
              text:
                typeof error.response.data === 'object'
                  ? (error.response.data as { detail: string }).detail
                  : 'エラーが発生しました',
              severity: 'error',
            })
        } else if (
          typeof error.response?.data === 'object' &&
          error.response.config.responseType === 'blob'
        ) {
          const text = await (error.response.data as Blob).text()
          const json = text.includes('detail')
            ? (JSON.parse(text) as { detail: string })
            : undefined

          if (showSnackbar) {
            showSnackbar({
              text:
                json?.detail ?? 'エラーが発生しました。時間をおいて再度お試しください。',
              severity: 'error',
            })
          }
        } else if (
          !error.response?.data ||
          !(error.response?.data as { detail: string }).detail
        ) {
          if (showSnackbar)
            showSnackbar({
              text: 'エラーが発生しました。時間をおいて再度お試しください。',
              severity: 'error',
            })
        }

        return Promise.reject(error)
      },
    )

    return client
  }
  return { apiClient }
}
