import { MyPromptIcon } from '@/components/icon/MyPromptsIcon'
import { ROUTE_PATH } from './route'
import { textColor } from '../themeConfigs/customTheme'

export const TEAM_MENU_ITEMS = [
  {
    id: 1,
    text: 'TEAM CHAT 一覧',
    icon: (isCurrent: boolean) => {
      return <MyPromptIcon strokeColor={isCurrent ? textColor.red : undefined} />
    },
    href: ROUTE_PATH.teams.path,
  },
  {
    id: 2,
    text: 'マイリスト',
    icon: (isCurrent: boolean) => {
      return <MyPromptIcon strokeColor={isCurrent ? textColor.red : undefined} />
    },
    href: ROUTE_PATH.myTeams.path,
  },
]
