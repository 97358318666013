export const StarIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.60294 1.58635C7.74984 1.23317 8.25016 1.23317 8.39705 1.58635L10.0224 5.49404C10.0843 5.64294 10.2243 5.74467 10.385 5.75755L14.6037 6.09576C14.985 6.12633 15.1396 6.60216 14.8491 6.85101L11.6349 9.6043C11.5125 9.70921 11.459 9.87382 11.4964 10.0307L12.4784 14.1474C12.5671 14.5195 12.1624 14.8135 11.8359 14.6142L8.22415 12.4081C8.08654 12.324 7.91346 12.324 7.77585 12.4081L4.16407 14.6142C3.83763 14.8135 3.43286 14.5195 3.52162 14.1474L4.50361 10.0307C4.54102 9.87382 4.48754 9.70921 4.36507 9.6043L1.15088 6.85101C0.860379 6.60216 1.01499 6.12633 1.39627 6.09576L5.61495 5.75755C5.77569 5.74467 5.91572 5.64294 5.97764 5.49405L7.60294 1.58635Z'
        fill='#FFD417'
      />
      <path
        d='M7.60294 1.58635C7.74984 1.23317 8.25016 1.23317 8.39705 1.58635L10.0224 5.49404C10.0843 5.64294 10.2243 5.74467 10.385 5.75755L14.6037 6.09576C14.985 6.12633 15.1396 6.60216 14.8491 6.85101L11.6349 9.6043C11.5125 9.70921 11.459 9.87382 11.4964 10.0307L12.4784 14.1474C12.5671 14.5195 12.1624 14.8135 11.8359 14.6142L8.22415 12.4081C8.08654 12.324 7.91346 12.324 7.77585 12.4081L4.16407 14.6142C3.83763 14.8135 3.43286 14.5195 3.52162 14.1474L4.50361 10.0307C4.54102 9.87382 4.48754 9.70921 4.36507 9.6043L1.15088 6.85101C0.860379 6.60216 1.01499 6.12633 1.39627 6.09576L5.61495 5.75755C5.77569 5.74467 5.91572 5.64294 5.97764 5.49405L7.60294 1.58635Z'
        fill='url(#paint0_linear_968_560)'
      />
      <path
        d='M7.60294 1.58635C7.74984 1.23317 8.25016 1.23317 8.39705 1.58635L10.0224 5.49404C10.0843 5.64294 10.2243 5.74467 10.385 5.75755L14.6037 6.09576C14.985 6.12633 15.1396 6.60216 14.8491 6.85101L11.6349 9.6043C11.5125 9.70921 11.459 9.87382 11.4964 10.0307L12.4784 14.1474C12.5671 14.5195 12.1624 14.8135 11.8359 14.6142L8.22415 12.4081C8.08654 12.324 7.91346 12.324 7.77585 12.4081L4.16407 14.6142C3.83763 14.8135 3.43286 14.5195 3.52162 14.1474L4.50361 10.0307C4.54102 9.87382 4.48754 9.70921 4.36507 9.6043L1.15088 6.85101C0.860379 6.60216 1.01499 6.12633 1.39627 6.09576L5.61495 5.75755C5.77569 5.74467 5.91572 5.64294 5.97764 5.49405L7.60294 1.58635Z'
        fill='url(#paint1_linear_968_560)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.8785 7.68293L11.6355 9.6043C11.513 9.70921 11.4595 9.87382 11.497 10.0307L12.479 14.1474C12.5677 14.5195 12.1629 14.8135 11.8365 14.6142L8.22473 12.4081C8.08711 12.324 7.91403 12.324 7.77642 12.4081L4.16464 14.6142C3.8382 14.8135 3.43344 14.5195 3.52219 14.1474L4.02451 12.0415C5.21618 9.33417 8.18881 7.41581 11.6673 7.41581C12.4335 7.41581 13.1751 7.50887 13.8785 7.68293Z'
        fill='url(#paint2_linear_968_560)'
      />
      <g filter='url(#filter0_i_968_560)'>
        <path
          d='M7.60294 1.58635C7.74984 1.23317 8.25016 1.23317 8.39705 1.58635L10.0224 5.49404C10.0843 5.64294 10.2243 5.74467 10.385 5.75755L14.6037 6.09576C14.985 6.12633 15.1396 6.60216 14.8491 6.85101L11.6349 9.6043C11.5125 9.70921 11.459 9.87382 11.4964 10.0307L12.4784 14.1474C12.5671 14.5195 12.1624 14.8135 11.8359 14.6142L8.22415 12.4081C8.08654 12.324 7.91346 12.324 7.77585 12.4081L4.16407 14.6142C3.83763 14.8135 3.43286 14.5195 3.52162 14.1474L4.50361 10.0307C4.54102 9.87382 4.48754 9.70921 4.36507 9.6043L1.15088 6.85101C0.860379 6.60216 1.01499 6.12633 1.39627 6.09576L5.61495 5.75755C5.77569 5.74467 5.91572 5.64294 5.97764 5.49405L7.60294 1.58635Z'
          stroke='#F2B302'
          strokeWidth='0.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_i_968_560'
          x='0.75'
          y='0.0714684'
          width='14.5'
          height='14.8578'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 0.66245 0 0 0 0 0.119434 0 0 0 1 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_968_560' />
        </filter>
        <linearGradient
          id='paint0_linear_968_560'
          x1='8'
          y1='8.25318'
          x2='8'
          y2='14.6785'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FDAB1A' stopOpacity='0' />
          <stop offset='1' stopColor='#FDAB1A' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_968_560'
          x1='8'
          y1='1.32147'
          x2='8'
          y2='9.9968'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#FEFCF5' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_968_560'
          x1='7.1633'
          y1='1.32147'
          x2='7.1633'
          y2='11.908'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.2' />
        </linearGradient>
      </defs>
    </svg>
  )
}
