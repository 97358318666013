import { Dispatch, SetStateAction } from 'react'
import { BasicCheckBoxAccordion } from '@/components/uis/Accordion/BasicCheckBoxAccordion'
import { IndexFile } from './IndexFileBox'

type IndexFile = {
  id: string
  file_name: string
}

type IndexFolder = {
  id: string
  name: string
  file_list?: IndexFile[]
  child_dir_list?: Omit<IndexFolder, 'child_dir_list'>[]
}

export type Index = IndexFolder[]

export type IndexAccordionProps = {
  item: Index
  expandedType: string
  checkedIndex: string[]
  setCheckedIndex: Dispatch<SetStateAction<string[]>>
  checkedFolder: string[]
  setCheckedFolder: Dispatch<SetStateAction<string[]>>
}

export const IndexAccordion = ({
  item,
  expandedType,
  checkedIndex,
  setCheckedIndex,
  checkedFolder,
  setCheckedFolder,
}: IndexAccordionProps) => {
  /**
   * 第2階層を取得する
   * @param id 第1階層のId
   * @returns 第2階層
   */
  const getChild = (id: string) => {
    return item.find(
      (f) =>
        f.id === id &&
        ((f.file_list && f.file_list.length > 0) ||
          (f.child_dir_list && f.child_dir_list?.length > 0)),
    )
  }
  /**
   * 第3階層を取得する
   * @param id 第2階層のId
   * @returns 第3階層
   */
  const getGrandChild = (id: string) => {
    return item
      .find((f) => f.child_dir_list && f.child_dir_list.find((file) => file.id === id))
      ?.child_dir_list?.find((f) => f.id === id)?.file_list
  }

  /**
   * 子要素の状態を第3階層まで一括で切り替える
   * @param id
   * @param isAdd チェック状態の配列に追加するか
   */
  const toggleChildChecked = (id: string, isAdd: boolean) => {
    const files: string[] = []
    const folders: string[] = []

    if (getChild(id)) {
      const child = getChild(id)
      if (child) {
        folders.push(child.id)
        child?.file_list?.forEach((f) => files.push(f.id))
        child?.child_dir_list?.forEach((f) => {
          folders.push(f.id)
          f.file_list?.forEach((grandChild) => files.push(grandChild.id))
        })
      }
    }
    if (getGrandChild(id)) {
      getGrandChild(id)?.forEach((m) => {
        files.push(m.id)
      })
    }
    if (isAdd) {
      setCheckedFolder((prev) => [...prev, ...folders.filter((f) => !prev.includes(f))])
      setCheckedIndex((prev) => [...prev, ...files.filter((f) => !prev.includes(f))])
    } else {
      setCheckedFolder((prev) => prev.filter((f) => !folders.includes(f)))
      setCheckedIndex((prev) => prev.filter((f) => !files.includes(f)))
    }
  }

  const toggleFileChecked = (id: string) => {
    if (checkedIndex.includes(id)) {
      setCheckedIndex((prev) => prev.filter((f) => f !== id))
    } else {
      setCheckedIndex((prev) => [...prev, id])
    }
  }

  const toggleFolderChecked = (id: string) => {
    const status = getFileCheckedArrayUnderLevel(id)
    const isAllChecked =
      status.length > 0
        ? getFileCheckedArrayUnderLevel(id).every((e) => !!e)
        : checkedFolder.includes(id)

    if (isAllChecked) {
      setCheckedFolder((prev) => prev.filter((f) => f !== id))
    } else {
      setCheckedFolder((prev) => [...prev, id])
    }
    toggleChildChecked(id, !isAllChecked)
  }

  /**
   * 配下の選択状態を全て取得する
   * @param id
   * @returns Array<boolean>
   */
  const getFileCheckedArrayUnderLevel = (id: string) => {
    const status: boolean[] = []

    if (getChild(id)) {
      const child = getChild(id)
      if (child?.file_list && child.file_list.length > 0) {
        child.file_list.forEach((f) => status.push(checkedIndex.includes(f.id)))
      }
      if (child?.child_dir_list) {
        child.child_dir_list.forEach((f) => {
          if (f.file_list && f.file_list.length > 0) {
            f.file_list?.forEach((grandChild) =>
              status.push(checkedIndex.includes(grandChild.id)),
            )
          } else {
            status.push(checkedFolder.includes(f.id))
          }
        })
      }
    } else if (getGrandChild(id)) {
      getGrandChild(id)?.forEach((f) => status.push(checkedIndex.includes(f.id)))
    }
    return status
  }

  const watchChildChecked = (id: string) => {
    const status = getFileCheckedArrayUnderLevel(id)
    return status.length > 0 && !status.every((e) => e === status[0])
  }

  /**
   * 全要素が選択済みか(子要素がなければFolder選択状態を見る)
   * @param id
   * @returns
   */
  const isUnderFileAllChecked = (id: string) => {
    const status = getFileCheckedArrayUnderLevel(id)
    if (status.length > 0) return status.every((e) => !!e)
    return checkedFolder.includes(id)
  }

  return item.map((parent) =>
    parent.child_dir_list ? (
      <BasicCheckBoxAccordion
        key={parent.id}
        id={parent.id}
        expandedType={`${expandedType}-${parent.id}`}
        title={parent.name}
        indeterminate={watchChildChecked(parent.id)}
        toggleChecked={toggleFolderChecked}
        checked={isUnderFileAllChecked(parent.id)}
      >
        {parent.child_dir_list.map((children) => {
          return (
            children.file_list && (
              <BasicCheckBoxAccordion
                isChild
                key={children.id}
                id={children.id}
                title={children.name}
                expandedType={`${expandedType}-${children.id}`}
                indeterminate={watchChildChecked(children.id)}
                toggleChecked={toggleFolderChecked}
                checked={isUnderFileAllChecked(children.id)}
              >
                {children.file_list.map((grandChild) => (
                  <IndexFile
                    key={grandChild.id}
                    id={grandChild.id}
                    filename={grandChild.file_name}
                    checked={checkedIndex.includes(grandChild.id)}
                    toggleChecked={toggleFileChecked}
                  />
                ))}
              </BasicCheckBoxAccordion>
            )
          )
        })}
        {parent.file_list?.map((children) => {
          return (
            <IndexFile
              key={children.id}
              id={children.id}
              filename={children.file_name}
              checked={checkedIndex.includes(children.id)}
              toggleChecked={toggleFileChecked}
            />
          )
        })}
      </BasicCheckBoxAccordion>
    ) : parent.file_list ? (
      <IndexFile
        key={parent.id}
        id={parent.id}
        filename={parent.name}
        checked={checkedIndex.includes(parent.id)}
        toggleChecked={toggleFileChecked}
      />
    ) : null,
  )
}
