export const QueryKeys = {
  informationDetail: ['information', 'detail'],
  information: (query: string = '') => ['information', query],
  students: ['admin', 'students'],
  teachers: ['admin', 'teachers'],
  chatRooms: ['admin', 'chatRooms'],
  chatRoom: ['admin', 'chatRoom', 'chatLogs'],
  group: ['group'],
  genre: ['genre'],
  box: ['box'],
  containingBox: ['box', 'containingBox'],
  promptsInBox: ['box', 'prompts'],
  prompt: ['prompt'],
  prompts: ['prompts', 'original-prompts'],
  myPrompts: ['prompts', 'myPrompts'],
  favoritePrompts: ['prompts', 'favorite', 'favoritePrompts'],
  policy: ['admin', 'policy'],
  policyDetail: ['admin', 'policy'],
  adminGroup: ['admin', 'group'],
  adminGenre: ['admin', 'genre'],
  feedback: ['admin', 'feedback'],
  feedbackDetail: ['admin', 'feedback'],
  AdminPrompt: ['admin', 'prompt'],
  AdminPrompts: ['admin', 'prompts'],
  index: ['index'],
  team: ['team'],
  teams: ['teams'],
  myTeams: ['teams', 'myTeams'],
  members: ['members'],
}

export const MutationKeys = {
  login: ['login'],
  me: ['me'],
  llmModels: ['llmModels'],
  chatTxt: ['chatTxt', 'chatRoomId'],
  history: ['chatRooms'],
  deleteHistory: ['deleteChatRoom'],
  fetchChatHistory: ['fetchChatHistory'],
  feedback: ['feedback'],
  incrementPromptUsed: ['prompt', 'use'],
  newPrompt: ['prompt', 'create'],
  updatePrompt: ['prompt', 'update'],
  deletePrompt: ['prompt', 'delete'],
  addFavorite: ['favorite', 'add'],
  removeFavorite: ['favorite', 'remove'],
  newBox: ['box', 'create'],
  deleteBox: ['box', 'delete'],
  changeBox: ['box', 'add', 'remove'],
  authority: ['authority'],
  csvDownload: ['admin', 'chatRooms', 'csv'],
  adminGroup: ['admin', 'group'],
  adminGenre: ['admin', 'genre'],
  deleteAdminPrompt: ['admin', 'deleteAdminPrompt'],
  updateAdminPrompt: ['admin', 'updateAdminPrompt'],
  duplicateChat: ['duplicate', 'chat'],
  createTeam: ['create', 'team'],
  deleteTeam: ['delete', 'team'],
  addMember: ['add', 'member', 'team'],
  deleteMember: ['delete', 'member', 'team'],
}
