import { usePathname } from 'next/navigation'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { SideMenuItem } from '@/components/layout/SideMenu/SideMenuItem'
import { ADMIN_MENU_ITEMS } from '@/utils/constants/adminMenuItems'
import { bgColor } from '@/utils/themeConfigs/customTheme'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${bgColor.lightestGray};
  padding: 40px 0 24px;
`

export const AdminSideMenu = () => {
  const pathName = usePathname()
  return (
    <Container>
      <Box>
        {ADMIN_MENU_ITEMS.map((item) => (
          <SideMenuItem
            key={item.id}
            text={item.text}
            href={item.href}
            startIcon={item.icon}
            isCurrent={pathName.includes(item.href)}
          />
        ))}
      </Box>
    </Container>
  )
}
