import { Button, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { CreatePromptIcon } from '@/components/icon/CreatePromptIcon'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { textColor } from '@/utils/themeConfigs/customTheme'

const ButtonContainer = styled(Button)`
  ${mediaQuery('tab')} {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
`

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.448;
  color: ${textColor.red};
`

type Props = {
  onClick: () => void
}

// TODO:新規作成系のボタンを共通化したい
export const CreateTeamButton = ({ onClick }: Props) => {
  const isMobileOrTablet = useIsMobileOrTablet()

  return (
    <ButtonContainer startIcon={<CreatePromptIcon />} onClick={onClick}>
      {isMobileOrTablet !== undefined && !isMobileOrTablet && <Text>新規作成</Text>}
    </ButtonContainer>
  )
}
