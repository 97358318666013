import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { BasicButton } from '@/components/uis/Button/BasicButton'
import { BasicModal } from '@/components/uis/Modal/BasicModal'

const Content = styled(Typography)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.448;
  text-align: center;
`

const ModalFooter = styled(Box)`
  display: flex;
  gap: 16px;
  padding: 16px 0 0 0;
  justify-content: center;
`

type Props = {
  open: boolean
  title: string
  onClose: () => void
  submit: () => void
  children: React.ReactNode
}

export const DeleteModal = ({ open, title, onClose, submit, children }: Props) => {
  return (
    <BasicModal open={open} onClose={onClose} title={title}>
      <>
        <Content>{children}</Content>
        <ModalFooter>
          <BasicButton
            label='キャンセル'
            variant='outlined'
            width='140px'
            onClick={onClose}
          />
          <BasicButton label='削除' width='140px' onClick={submit} />
        </ModalFooter>
      </>
    </BasicModal>
  )
}
